import { useState } from 'react';
let useLayoutEffect;
if (typeof window !== 'undefined') {
  // Ensure useLayoutEffect is only used on the client side
  useLayoutEffect = require('react').useLayoutEffect;
} else {
  useLayoutEffect = () => {};
}

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false)

  useLayoutEffect(() => {
    if (element.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setState(entry.isIntersecting)
            observer.unobserve(element.current)
          }
        },
        { rootMargin }
      )

      element.current && observer.observe(element.current)

      return () => observer.unobserve(element.current)
    }
  }, [])

  return isVisible
}

export default useIntersection
